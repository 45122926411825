<template>
    <div>
        <b-col>
            <img class="mb-3 mt-2 center-horizontal logo" src="@/assets/logo.jpg" alt="Parsill"/>
            <div class="mx-3">
                <h2>Parsill is a place to store creative projects and gain inspiration from others.</h2>
            </div>
        </b-col>
        <LoginForm @goToSignup="goToSignup" @goToForgotPassword="goToForgotPassword"/>
    </div>
</template>

<script>
import { } from '../db';

import LoginForm from '../components/login/LoginForm.vue';
export default {
    
    components: {
        LoginForm
    },
    methods: {
        goToSignup() {
            this.$emit("goToSignup");
        },
        goToForgotPassword() {
            this.$emit("goToForgotPassword");
        }
    }
}
</script>

<style scoped>
    .logo {
        width: 70vw;
    }
</style>