import Vue from "vue";
import Vuex from "vuex";
import { firestoreAction, vuexfireMutations } from 'vuexfire';
import { db } from './db';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        parsillUser: null,
        projects: [],
        tabIndex: 0,
        savedProjects: [],
        profilePicture: "#",
        connectionType: "followers",
        searchType: "people",
        myProjectImages: new Map(),
        categories: [],
        authenticated: null,
        connections: [],
        connectionPictures: {},
        // temp stuff
        temporaryGalleryImgs: [],
        temporaryGalleryContentTypes: [],
        featuredUrls: {},
        projectImageCache: {},
    },
    mutations: {
      // other mutations
      ...vuexfireMutations,
      updateTabIndex(state, index) {
          state.tabIndex = index;
      },
      addSavedProject(state, project) {
          state.savedProjects.push(project);
      },
      updateProfilePicture(state, url) {
          state.profilePicture = url;
      },
      updateConnectionType(state, type) {
          state.connectionType = type;
      },
      updateSearchType(state, type) {
          state.searchType = type;
      },
      cacheProjectPicture(state, {id, url}) {
        state.myProjectImages.set(id, url);
      },
      updateCategories(state, categories) {
        state.categories = categories;
      },
      updateAuthenticated(state, authenticated) {
          state.authenticated = authenticated;
      },
      updateConnections(state, connections) {
          state.connections = connections;
      },
      cacheConnectionPicture(state, {id, url}) {
          state.connectionPictures[id] = url;
      },
      updateTemporaryGallery(state, {imgUrls, contentTypes}) {
          console.log("updating the temporary cache:");
          console.log(imgUrls);
          console.log(contentTypes);
          state.temporaryGalleryImgs = imgUrls;
          state.temporaryGalleryContentTypes = contentTypes;
      },
      updateFeaturedUrls(state, {categoryId, url}) {
        state.featuredUrls[categoryId] = url;
      },
      updateProjectImageCache(state, {id, url}) {
        state.projectImageCache[id] = url;
      }
    },
    actions: {
        bindUser: firestoreAction(({ bindFirestoreRef }, userId) => {
            console.log("binding userId: " + userId);
            return bindFirestoreRef("parsillUser", db.collection("users").doc(userId));
        }),
        bindUserProjects: firestoreAction(({ bindFirestoreRef }, userId) => {
            console.log("looked for projects where userId = " + userId);
            return bindFirestoreRef("projects", db.collection("projects").where("creatorId", "==", userId));
        }),
    }
  });