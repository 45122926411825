<template>
    <div>
        <div class="expanded-width parsillBlueBg">
            <b-navbar class="parsillBlueBg" fixed="top">
                <div class="w-100">
                    <div class="d-none d-sm-block computer-warning p-3">Parsill is formatted for mobile. For best experience, open this link on your phone.</div>
                    <div class="row justify-content-around">
                        <div class="col">
                            <h3 v-if="hasBackButton==true"><b-icon icon="arrow-left-square-fill" class="white mt-3" @click="navPop"></b-icon></h3>
                            <h3 v-else-if="hasLogout==true"><b-icon icon="box-arrow-left" class="white mt-3" @click="logout"></b-icon></h3>
                            <h3 v-else><b-icon icon="arrow-left-square-fill" class="mt-3" style="visibility:hidden"></b-icon></h3>
                        </div>
                        <div class="col" @click="logoClick">
                            <img class="p-1 shadow-sm parsillBlueBg center-horizontal" src="../../assets/logo.jpg" alt="Parsill" height="60px"/>
                        </div>
                        <div class="col">
                            <h3>
                                <b-icon v-if="actionBootstrapIcon" :icon="actionBootstrapIcon" class="white mt-3" @click="actionClick"></b-icon>
                                <b-icon v-else icon="square" class="mt-3" style="visibility:hidden"></b-icon>
                            </h3>
                            
                        </div>
                    </div>
                    
                </div>
            </b-navbar>
            <div class="safearea"></div>
            <div class="d-none d-sm-block safearea"></div>
        </div>
        <b-form class="mt-5 mx-4" @submit.prevent="onSubmit">
            <b-form-group id="email-input" class="py-1">
                <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter your email"
                    size="lg"
                    :state="validateState('email')"
                    aria-describedby="input-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback
                class="validator-text pl-2"
                id="input-1-live-feedback"
                >Please enter a valid email</b-form-invalid-feedback>
            </b-form-group>
            
            <b-button class="mt-5 mb-3" type="submit" variant="primary" size="lg">Send Recovery Email</b-button>
        </b-form>
    </div>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import { required, email } from "vuelidate/lib/validators";
    import { auth } from "../../db";

    export default {
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    email: null,
                },
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email,
                },
            }
        },
        methods: {
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    alert("Invalid email");
                    return;
                }

                auth.sendPasswordResetEmail(this.form.email.trim());

                alert("An email should arrive shortly to reset your password");

            },
        }
    }
</script>

<style>
    .btn-primary {
        background-color: #09256e !important;
    }
    .validator-text {
        font-size: 1rem;
        text-align: left;
    }

    .appbar-layout {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .appbar-item {
        display: table-cell;
        text-align: center;
    }

    .safearea {
        height: 65px;
    }

    .computer-warning {
        font-weight: bold;
        background-color: yellow;
        color: black;
        font-size: 1.2em;
    }
</style>