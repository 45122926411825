<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>
import { db, storage, auth } from './db';

export default {
  mounted() {
    auth.onAuthStateChanged((user) => {
      if (user == null) {
        this.$store.commit("updateAuthenticated", false);
        this.$router.push("/");
      } else {
        console.log("getting info");
        this.getCategories();
        this.getUserInfo(user.uid);
        this.$store.commit("updateAuthenticated", true);
        this.getProfilePicture(user.uid);
        this.getProjectPictures(user.uid);
        this.getConnections(user.uid);
      }
    });
  },
  methods: {
    getUserInfo(uid) {
      console.log("getting user info for id: " + uid);
          this.$store.dispatch("bindUser",  uid);
          this.$store.dispatch("bindUserProjects", uid);
      },  
      getProfilePicture(uid) {
          var profileRef = storage.ref().child("users/").child(uid).child("/profile");
          profileRef.getDownloadURL()
          .then((url) => {
              this.$store.commit("updateProfilePicture", url);
          })
          .catch((err) => {
              console.log("Error loading profile picture: " + `${err}`);
          })
      },
      getProjectPictures(uid) {
        db.collection("projects").where("creatorId", "==", uid).get().then((querysnap) => {
          for (var i = 0; i < querysnap.docs.length; i++) {
            let id = querysnap.docs[i].id;

            let imageRef = storage.ref().child("projects/").child(id).child("/main");
            imageRef.getDownloadURL()
            .then((url) => {
                console.log("url: " + url);
                this.$store.commit("cacheProjectPicture", {id, url});
            })
            .catch((err) => {
                console.log(`Error loading project pictures: ${err}`);
            });
          }
        });
      },
      getCategories() {
        db.collection("categories")
        .orderBy("displayOrder")
        .get()
        .then((querysnap) => {
          var categories = [];
          for (var i=0; i < querysnap.docs.length; i++) {
            var data = querysnap.docs[i].data();
            data.id = querysnap.docs[i].id;
            categories.push(data);
          }
          this.$store.commit("updateCategories", categories);
        })
        .catch((err) => {
          console.log(`Error loading categories: ${err}`);
        });
      },
      getConnections(uid) {
        var allConnections = [];
        var idsSeen = [];
        db.collection("users")
        .where("following", "array-contains", uid)
        .get()
        .then((querysnap) => {
          let docs = querysnap.docs;
          for (var i=0; i < docs.length; i++) {
            var connection = docs[i].data();
            connection.id = docs[i].id;
            allConnections.push(connection);
            idsSeen.push(connection.id);
          }
          console.log(`after following allConnections contains ${allConnections.length} results`);
          db.collection("users")
          .where("followers", "array-contains", uid)
          .get()
          .then((querysnap) => {
            let docs = querysnap.docs;
            for (var i=0; i < docs.length; i++) {
              var connection = docs[i].data();
              connection.id = docs[i].id;
              if (!idsSeen.includes(connection.id)) {
                allConnections.push(connection);
              }
              
            }
            console.log(`after followers allConnections contains ${allConnections.length} results`);
            // load all the connection images here

            // commit to store
            this.$store.commit("updateConnections", allConnections);
          })
          .catch((err) => {
            console.log(`Error loading user following: ${err}`);
          });
        })
        .catch((err) => {
          console.log(`Error loading connections: ${err}`);
        });
      },
  }
}
</script>

<style>
#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.parsillBlueBg {
  background-color: #09256e;
}

.parsillBlue {
  color: #09256e;
}

.parsillOrangeBg {
  background-color: #F26419;
}

.parsillOrange {
  color: #F26419;
}

.parsillTertiary {
  color: #3F784C;
}

.parsillTertiaryBg {
  background-color: #3F784C;
}

.expanded-viewport {
    height: 100vh;
}

.expanded-width {
  width: 100%;
}

.white {
  color: white;
}

.no-overflow {
  overflow: hidden;
}

 /* project cards */
 .project-card-dim {
   width: 35vw;
   height: 50vw;
   
 }

 .bottom-margin-box {
      height: 100px;
  }

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

</style>
