<template>
    <div >
        
        <b-list-group class="px-1" v-if="connections.length > 0">
            <li v-for="connection in connections" :key="connection.id" class="list-group-item d-flex justify-content-start align-items-center" @click="viewConnection(connection.id)">
                <!-- <div class="profile-dim">
                    <img v-if="profileImageLoaded(connection.id)" :src="profilePictures[connection.id]" class="rounded-circle profile-dim" alt="image">
                    <b-spinner v-else></b-spinner>
                </div> -->
                <div class="sized-box"></div>
                <div class="font-large ml-4">{{connection.username}}</div>
            </li>
        </b-list-group>
        <div class="mt-5" v-else>
            <h3>{{emptyMessage}}</h3>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex';

export default {
    props: {
        connections: [],
        connectionType: {type: String, required: true},
    },
    computed: {
        emptyMessage() {
            if (this.connectionType == "following") {
                return "You don't follow anyone yet."
            } else {
                return "No followers yet."
            }
        },
        ...mapState({
            connectionPictures: (state) => state.connectionPictures,
        }),
    },
    data() {
        return {
            profilePictures: {type: Map, }
        }
    },
    methods: {
        // profileImageLoaded(id) {
        //     return this.profilePictures[id] != null;
        // },
        // getProfilePicture(id) {
        //     var ref = storage.ref().child(`users/${id}/profile`);
        //     console.log(ref);
        //     ref.getDownloadURL()
        //     .then((url) => {
        //         console.log("got it " + url);
                
        //         this.pro
        //         this.$store.commit("cacheConnectionPicture", {id, url});
        //         console.log(this.profilePictures[id]);
                
        //     })
        //     .catch((err) => {
        //         console.log(err);
        //     });
        // },
        viewConnection(id) {
            this.$router.push("viewprofile/" + id);
        }
    },
    mounted() {
        // var amountToLoad = this.connections.length;
        // var amountLoaded = 0;
        // for (var i=0; i < this.connections.length; i++) {
        //     var connection = this.connections[i];
        //     if (this.connectionPictures[connection.id] == null) {
        //         this.getProfilePicture(connection.id);
        //     } else {
        //         this.profilePictures[connection.id] = this.connectionPictures[connection.id];
        //     }
        //     amountLoaded ++;
        //     console.log("loaded: " + `${amountLoaded}`)
        //     if (amountToLoad == amountLoaded) {
        //         this.profilePictures = this.profilePictures + [];
        //     }
        // }
    }
}
</script>

<style scoped>
    .font-large {
        font-size: 1.2em;
    }

    .profile-dim {
        width: 10vw;
        height: 10vw;
    }

    .trim {
        /* max-height: 50px; */
        overflow: hidden;
    }

    .sized-box {
        width: 10px;
        margin-left: 10;
        margin-right: 10;
    }
</style>