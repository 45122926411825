<template>
    <div>
        <div @click="goToProject" class="py-2 px-2">
            <div class="card-header shadow rounded" :style="borderStyling">
                <div class="d-flex flex-row justify-content-between mb-1" style="height: 30px">
                    <div class="pt-1">
                        <h6>
                            <span>
                                <strong :style="featuredTextColor">{{category.title}}</strong>
                            </span>
                        </h6>
                    </div>

                    <div>
                        <b-icon v-if="isFeatured" icon="arrow-up-square-fill" variant="success" style="width: 25px; height: 25px;"></b-icon>
                    </div>
                </div>
                <div class="large-font">
                    <span v-if="isFeatured" class="large-font">
                        {{project.title}}
                    </span>
                    <h5 v-else>
                        {{project.title}}
                    </h5>
                </div>
                <div v-if="imgUrl" class="img-container image-trim" id="img-container">
                    <img :src="imgUrl" alt="project image" class="card-img" width="100%">
                </div>
                <div v-else class="loading-div text-center w-100 mt-5">
                    <b-spinner></b-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { storage } from '../../db';
    export default {
        props: {
            project: {type: Object, required: true},
            category: {type: Object, required: true},
            preloadedImg: {type: String,},
            isFeatured: {type: Boolean, default: false},
        },
        data() {
            return {
                imgUrl: this.preloadedImg,
            }
        },
        methods: {
            goToProject() {
                this.$emit("goToProject", this.project);
            },
            async loadProjectImg() {
                const ref = storage.ref(`/projects/${this.project.id}/main`);

                try {
                    const downloadUrl = await ref.getDownloadURL();
                    this.imgUrl = downloadUrl;
                    this.$store.commit("updateProjectImageCache", {id: this.project.id, url: this.imgUrl})
                } catch (err) {
                    console.log("something went wrong loading an image for project " + this.project.title);
                    console.log(err);
                }
            }
        },
        
        computed: {
            borderStyling() {
                console.log(`border: ${this.isFeatured ? "7": "5"}px #${this.category.color} solid`)
                return `border: ${this.isFeatured ? "7": "5"}px #${this.category.color} solid`;   
            },
            featuredTextColor() {
                return `color: #${this.category.color};`;
            }
        },
        mounted() {
            if (this.preloadedImg == null) {
                this.loadProjectImg();
            }
        }
    }
</script>

<style scoped>
    /* #categoryCard {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        /* background-color: #BACCEF; */


    .card-header {
        background-color: white;
        /* text-align: left; */
        /* padding-bottom: 200px; */
    }

    .large-font {
        font-size: larger;
        font-weight: bold;
    }

    .image-trim {
        max-height: 15rem;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

</style>