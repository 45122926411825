<template>
    <div>
        <div class="d-flex flex-row justify-content-between px-4">
            <button class="rounded customize-button" @click="createNewProject">
                <div class="d-flex flex-row px-1 pt-1">
                    <h6 class="mt-1 mx-2">New Project</h6>
                    <h5><b-icon icon="plus"></b-icon></h5>
                </div>
            </button>
            <button class="rounded customize-button" @click="editProjectOrder">
                <div class="d-flex flex-row px-1 pt-1">
                    <h6 class="mt-1 mx-2">Customize</h6>
                    <h5><b-icon icon="view-list"></b-icon></h5>
                </div>
            </button>
        </div>
        <div class="projects-list px-4 my-2">
            <home-project-card v-for="project in sortedProjects" :key="project.id" 
            :project="project" 
            :category="categoryFromProject(project)" 
            :preloadedImg="myProjectImages.get(project.id)"
            @goToProject="viewMyProject"
        />
        </div>
        <!-- <div class="d-flex flex-row justify-content-around mt-4">
            <NewProjectCard @createNewProject="createNewProject"/>
            <MyProjectCard v-if="projects.length > 0" :project="sortedProjects[0]" @viewMyProject="viewMyProject" :preloadedImg="myProjectImages.get(sortedProjects[0].id)"/>
            <div v-else class="project-card-dim">
            </div>
        </div>
        <div v-for="(row, index) in projectsChunked" :key="index" class="d-flex flex-row justify-content-around mt-4">
            <MyProjectCard v-if="projects.length > 0" :project="row[0]"  @viewMyProject="viewMyProject" :preloadedImg="myProjectImages.get(row[0].id)"/>
            <MyProjectCard v-if="row.length > 1" :project="row[1]"  @viewMyProject="viewMyProject" :preloadedImg="myProjectImages.get(row[1].id)"/>
            <div v-else class="project-card-dim">
            </div>
        </div> -->
        
    </div>
</template>

<script>
// import NewProjectCard from '../../components/profile/nested/NewProjectCard.vue';
// import MyProjectCard from '../../components/profile/nested/MyProjectCard.vue';

import { mapState } from 'vuex';
import HomeProjectCard from '../home/HomeProjectCard.vue';

export default {
    components: {
        // NewProjectCard,
        // MyProjectCard,
        HomeProjectCard,
    },
    props: {
        parsillUser: null,
        projects: {type: Array, default: () => []},
    },
    computed: {
        projectsChunked: function() {
            if (this.projects.length == 0) return [];

            var chunks = [];

            for (var i=1; i < this.sortedProjects.length; i++) {
                if (i % 2 != 0) {
                    // add new chunk
                    chunks.push([]);
                }

                // add project to latest chunk
                chunks[chunks.length-1].push(this.sortedProjects[i]);
            }

            return chunks;
        },
        sortedProjects: function() {
            if (this.projects.length == 0) return [];

            var sortedProjects = [...this.projects];

            if (this.parsillUser.projectOrder) {
                // means we have a set order for the projects, so sort
                sortedProjects.sort((a, b) => {
                    var aIndex = this.parsillUser.projectOrder.indexOf(a.id);
                    var bIndex = this.parsillUser.projectOrder.indexOf(b.id);
                    
                    if (aIndex == -1) aIndex = 1000;
                    if (bIndex == -1) bIndex = 1000;

                    return aIndex - bIndex;
                });
            }

            return sortedProjects;
        },
        ...mapState({
            myProjectImages: (state) => state.myProjectImages,
            categories: (state) => state.categories,
        }),
    },
    methods: {
        createNewProject() {
            if (this.parsillUser.profileComplete == false) {
                alert("Complete your profile to start posting!");
                return;
            }
            this.$router.push(`/create/project`);
        },
        viewMyProject(project) {
           this.$router.push('/project/' + project.id);
        },
        editProjectOrder() {
            this.$router.push({name: "Edit Order", params: {projects: this.sortedProjects}});
        },
        categoryFromProject(project) {
            return this.categories.find((e) => e.id == project.categoryId);
        }
    },
}
</script>

<style scoped>
    .customize-button {
        background: lightgray;
    }
</style>