// Import the functions you need from the SDKs you need

import firebase from "firebase";
import 'firebase/firestore';
import 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAyRV5h_KEScAdnJlxdZ81tZhdm44Rr0D8",
  authDomain: "parsillfb.firebaseapp.com",
  projectId: "parsillfb",
  storageBucket: "parsillfb.appspot.com",
  messagingSenderId: "303018484757",
  appId: "1:303018484757:web:9977363dd346bebc11da1c",
  measurementId: "G-EF5E3SMHBG"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const fieldPath = firebase.firestore.FieldPath;
const fieldValue = firebase.firestore.FieldValue;
const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

const { TimeStamp } = firebase.firestore;

export {
  db,
  auth,
  storage,
  fieldPath,
  fieldValue,
  TimeStamp, 
}