<template>
    <div>
        <b-navbar fixed="bottom" type="light" variant="light">
            <b-navbar-nav fill class="w-100">
                <b-nav-item @click="updateIndex(0)" class="px-2">
                    <b-icon v-if="currentIndex === 0" icon="house-fill" class="parsillBlue"></b-icon>
                    <b-icon v-else icon="house-fill" variant="secondary"></b-icon>
                </b-nav-item>
                <b-nav-item @click="updateIndex(1)" class="px-2">
                    <b-icon v-if="currentIndex === 1" icon="person-fill" class="parsillBlue"></b-icon>
                    <b-icon v-else icon="person-fill" variant="secondary"></b-icon>
                </b-nav-item>
                <!-- <b-nav-item @click="updateIndex(4)" class="px-2">
                    <b-col>
                        <p>Add Project</p>
                        <b-icon v-if="currentIndex === 4" icon="plus-square-fill" class="parsillBlue"></b-icon>
                        <b-icon v-else icon="plus-square-fill" variant="secondary"></b-icon>
                        
                    </b-col>
                </b-nav-item> -->
                <b-nav-item @click="updateIndex(4)" class="px-2">
                    <b-icon v-if="currentIndex === 4" icon="plus-square-fill" class="parsillBlue"></b-icon>
                    <b-icon v-else icon="plus-square-fill" variant="secondary"></b-icon>
                </b-nav-item>
                <b-nav-item @click="updateIndex(2)" class="px-2">
                    <b-icon v-if="currentIndex === 2" icon="people-fill" class="parsillBlue"></b-icon>
                    <b-icon v-else icon="people-fill" variant="secondary"></b-icon>
                </b-nav-item>
                <b-nav-item @click="updateIndex(3)" class="px-2">
                    <b-icon v-if="currentIndex === 3" icon="search" class="parsillBlue"></b-icon>
                    <b-icon v-else icon="search" variant="secondary"></b-icon>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        props: {
            currentIndex: {type: Number, required: true, default: 0},
        },
        methods: {
            updateIndex(index) {
                this.$emit("updateIndex", index);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>