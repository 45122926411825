<template>
    <div>   
        <b-container>
            <b-form class="mt-5" @submit.prevent="onSubmit">
                <b-form-group id="email-input" class="my-2">
                    <b-form-input
                        id="input-1"
                        v-model="form.email"
                        type="email"
                        placeholder="Email"
                        size="lg"
                        :state="validateState('email')"
                    aria-describedby="input-1-live-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                    class="validator-text pl-2"
                    id="input-1-live-feedback"
                    >Please enter a valid email</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="password-input" class="my-2">
                    <b-form-input
                        id="input-2"
                        v-model="form.password"
                        type="password"
                        placeholder="Password"
                        size="lg"
                        :state="validateState('password')"
                        aria-describedby="input-2-live-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                    class="validator-text pl-2"
                    id="input-2-live-feedback"
                    >Please enter a valid password</b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="confirm-password-input" class="my-2">
                    <b-form-input
                        id="input-3"
                        v-model="form.confirmPassword"
                        type="password"
                        placeholder="Confirm password"
                        size="lg"
                        :state="validateState('confirmPassword')"
                        aria-describedby="input-3-live-feedback"
                    >
                    </b-form-input>
                    <b-form-invalid-feedback
                    class="validator-text pl-2"
                    id="input-3-live-feedback"
                    >Passwords must match</b-form-invalid-feedback>
                </b-form-group>
                <b-spinner class="mt-5 mb-5" v-if="loading == true"></b-spinner>
                <b-button v-else class="mt-5 mb-5 parsillBlueBg" type="submit" size="lg">Create Account</b-button>
            </b-form>
            
            <button type="button" class="btn btn-link" @click="goToLogin">Already have an account?</button>
        </b-container>
    </div>
</template>

<script>
import { auth, db } from '../../db';
import { validationMixin } from 'vuelidate';
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
export default {
    mixins: [validationMixin],
    validations: {
        form: {
            email: {
                required,
                email,
            },
            password: {
                required,
                minLength: minLength(6),
            },
            confirmPassword: {
                required,
                minLength: minLength(6),
                sameAs: sameAs(function() {return this.form.password}),
            }
        }
    },
    data() {
        return {
            form: {
                email: null,
                password: null,
                confirmPassword: null,
            },
            loading: false,
        }
    },   
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        goToLogin() {
            this.$emit("goToLogin");
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            this.loading = true;
            auth.createUserWithEmailAndPassword(this.form.email, this.form.password)
            .then((credential) => {
                var uid = credential.user.uid;
                
                db.collection("users").doc(uid).set({
                    email: credential.user.email,
                    profileComplete: false,
                    followers: [],
                    following: [],
                    myProjects: [],
                    savedProjects: [],
                })
                .then(() => {})
                .catch((err) => {
                    console.log(err);
                    alert(`Error creating user document: ${err}`);
                    this.loading = false;
                });
            })
            .catch((err) => {
                alert(`Error creating account: ${err}`);
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>