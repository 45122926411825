<template>
    <div>
        <ForgotPassword v-if="showForgotPassword==true"/>
        <Login v-else-if="showLogin==true" @goToSignup="goToSignup" @goToForgotPassword="goToForgotPassword"/>
        <Signup v-else @goToLogin="goToLogin"/>
        <div class="bottom-margin-box"></div>
    </div>
</template>

<script>
import ForgotPassword from './nested/ForgotPassword.vue';
import Signup from './Signup.vue';
import Login from './Login.vue';
export default {
    components: {
        ForgotPassword,
        Signup,
        Login,
    },
    data() {
        return {
            showLogin: false,
            showForgotPassword: false,
        }
    },
    methods: {
        goToLogin() {
            this.showLogin = true;
            this.showForgotPassword = false;
        },
        goToSignup() {
            this.showLogin = false;
            this.showForgotPassword = false;
        },
        goToForgotPassword() {
            this.showForgotPassword = true;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>