<template>
    <div class="expanded-viewport no-overflow">
        <h3 class="mt-4 mb-3">Your Connections</h3>
        <div class="row mt-1">
            <div class="col">
                <button v-if="connectionType=='followers'" type="button" class="btn btn-primary btn-lg py-1">Followers</button>
                <button @click="updateConnectionType('followers')" v-else type="button" class="btn btn-light btn-lg py-1">Followers</button>
            </div>
            <div class="col">
                <button v-if="connectionType=='following'" type="button" class="btn btn-primary btn-lg py-1">Following</button>
                <button @click="updateConnectionType('following')" v-else type="button" class="btn btn-light btn-lg py-1">Following</button>
            </div>
        </div>
        <hr>
        <ConnectionsList :connections="relevantConnections" :connectionType="connectionType"/>
    </div>
</template>

<script>
import ConnectionsList from '../components/connections/ConnectionsList.vue';
import { mapState } from 'vuex';

export default {
    components: {
        ConnectionsList,
    },
    methods: {
        updateConnectionType(type) {
            this.$store.commit("updateConnectionType", type);
        }
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            connectionType: (state) => state.connectionType,
            connections: (state) => state.connections,
        }),
        relevantConnections: function() {
            console.log("going relevant connections with following being: " + `${this.parsillUser.following}`);
            if (this.connectionType == "followers") {
                var followers = this.connections.filter((connection) => (this.parsillUser?.followers ?? []).includes(connection.id)) ?? [];
                console.log(`followers length = ${followers.length}`);
                if (followers.length > 0) {
                    followers.sort((a, b) => {
                        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });
                }
                return followers;
            } else {
                var following = this.connections.filter((connection) => {
                    return (this.parsillUser?.following ?? []).includes(connection.id);
                }) ?? [];
                if (following.length > 0) {
                    following.sort((a, b) => {
                        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });
                }
                
                return following;
            }
        }
    }
}
</script>

<style scoped>

</style>