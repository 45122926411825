import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeWrapper.vue'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import {vSelect} from 'vue-select';
import "vue-select/dist/vue-select.css";

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component("v-select", vSelect);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/categories',
  //   name: "Categories",
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: '/:category',
    name: 'Category',
    component: Home,
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: () => import('../views/nested/ProjectView.vue'),
  },
  {
    path: '/project/:id/gallery',
    name: 'Project Gallery',
    component: () => import('../views/nested/ProjectGallery.vue'),
  },
  {
    path: '/project/:id/image',
    name: 'View Project Image',
    component: () => import('../views/nested/ExpandedImage.vue'),
  },
  {
    path: '/project/:id/pdf',
    name: "View PDF",
    component: () => import('../views/nested/PDFViewer.vue'),
  },
  {
    path: '/create/project',
    name: 'Create Project',
    component: () => import('../views/nested/CreateProject.vue'),
  },
  {
    path: '/edit/order/',
    name: "Edit Order",
    component: () => import("../views/nested/EditProjectOrder.vue"),
  },
  {
    path: '/edit/project/',
    name: "Edit Project",
    component: () => import("../views/nested/EditProject.vue"),
  },
  {
    path: '/profile/complete',
    name: "Complete Profile",
    component: () => import('../views/nested/CompleteProfile.vue'),
  },
  {
    path: '/profile/saved',
    name: "Saved Projects",
    component: () => import('../views/nested/SavedProjects.vue'),
  },
  {
    path: '/viewprofile/:id',
    name: "View Profile",
    component: () => import('../views/nested/OtherProfile.vue'),
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
