<template>
    <div class="no-overflow">
        <ProfileHeader :parsillUser="parsillUser" :projectCount="projects.length" :saveCount="saved" :imgUrl="profilePicture"/>
        <ProfileContent :parsillUser="parsillUser" :projects="projects"/>
        <div class="bottom-margin-box"></div>
    </div>
</template>

<script>
import ProfileHeader from '../components/profile/ProfileHeader.vue';
import ProfileContent from '../components/profile/ProfileContent.vue';

import { mapState } from 'vuex';

export default {
    components: {
        ProfileHeader,
        ProfileContent,
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            projects: (state) => state.projects,
            profilePicture: (state) => state.profilePicture,
        }),
        saved: function() {
            var saved = 0;
            for (var i=0; i < this.projects.length; i++) {
                var proj = this.projects[i];
                if (proj.saves != null) {
                    saved += proj.saves;
                }
            }
            return saved;
        }
    },
    mounted() {
        
    }
}
</script>

<style scoped>
    
</style>