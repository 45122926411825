<template>
    <div v-if="authenticated == null">
        <AppbarFixed />
        <div class="mt-5">
            <b-spinner class="mt-5"></b-spinner>
        </div>
    </div>
    <div class="expanded-viewport" v-else-if="authenticated==true">
        <AppbarFixed :actionBootstrapIcon="actionIcon" @actionClick="actionClick" :hasLogout="tabIndex==1 || tabIndex==0" @logout="logout" @logoClick="logoClick"/>
        <CallToAction v-if="tabIndex==0" @updateIndex="updateIndex"/>
        <Profile v-if="tabIndex==1"/>
        <Connections v-if="tabIndex==2"/>
        <Search v-if="tabIndex==3"/>
        <BottomNavBar v-if="tabIndex != 0" :currentIndex="tabIndex" @updateIndex="updateIndex"/>
    </div>
    <div v-else>
        <AuthWrapper/>
    </div>
</template>

<script>
// import Appbar from '../components/general/Appbar.vue';
import AppbarFixed from '../components/general/AppbarFixed.vue';
// import Home from './Home.vue';
import CallToAction from './CallToAction.vue';
import Profile from './Profile.vue';
import Connections from './Connections.vue';
import Search from './Search.vue';
import BottomNavBar from '../components/general/BottomNavBar.vue';

import AuthWrapper from './AuthWrapper.vue';
import { auth } from '../db';
import { mapState } from 'vuex';

export default {
    components: {
        AppbarFixed,
        // Home,
        CallToAction,
        Profile,
        Connections,
        Search,
        BottomNavBar,
        AuthWrapper,
    },
    computed: {
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            tabIndex: (state) => state.tabIndex,
            authenticated: (state) => state.authenticated,
        }),
        actionIcon: function() {
            switch (this.tabIndex) {
                case 1:
                    return "folder-fill";
                case 2:
                    return "envelope-fill";
                default:
                    return null;
            }
        },
        webShareApiSupported() {
            return navigator.share
        }
    },
    methods: {
        
        updateIndex(index) {
            if (index == 4) {
                // means its create project
                this.$router.push("/create/project");
                index = 1;
            }
            this.$store.commit("updateTabIndex", index);
            this.moveUp();
        },
        actionClick() {
            switch (this.tabIndex) {
                case 1:
                    this.$router.push("/profile/saved");
                    break;
                case 2:
                    this.inviteUser();
                    break;
                default:
                    return;
            }
        },
        moveUp() {
           window.scrollTo(0,0);
        },
        inviteUser() { 
            if (this.webShareApiSupported) {
                navigator.share({
                    text: "Join a community of makers at:",
                    url: "https://parsillfb.web.app/",
                });
            } else {
                alert("Your browser does not support web sharing. We suggest copying the link to Parsill and sending it manually.");
            }
        },
        logout() {
            let shouldLogout = confirm("Log out of Parsill?");

            if (!shouldLogout) return;


            auth.signOut();
        },
        logoClick() {
            this.updateIndex(0);
        }
        
    },
    mounted() {
        var destinationIndex = this.$route.params.index;
        if (destinationIndex == null) return;
        
        this.updateIndex(destinationIndex);
    }
}
</script>

<style scoped>
</style>