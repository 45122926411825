<template>
  <div class="expanded-viewport">
    <!-- <Home v-if="showCategories == true"/> -->
    <div class="no-overflow py-4 px-4">
      <!-- <b-container class="pb-4 pt-4 px-3"> -->
      <h5 class="pb-3 call-to-action" >
        <em>Store projects and gain inspiration</em>
      </h5>
      <div class="dropdown-container">
        <b-dropdown id="category-dropdown" variant="outline-dark" :text="selectedCategory.title" class="m-md-2">
          <b-dropdown-item v-for="category in categoryDropdownOptions" :key="category.id" @click="selectCategory(category)">
            {{category.title}}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="mt-3 mb-5" v-if="selectedCategory.id == 'all'">
        <!-- <home-category-card v-for="category in categories" :key="category.id" :category="category" @goToCategory="goToCategory"/> -->
        <div v-if="loadingProjects && loadedProjects.length == 0" class="mt-5">
          <b-spinner></b-spinner>
        </div>
        <div v-else>
          <home-category-card v-for="project in featuredProjects" :key="project.id" 
            :category="categories.find((e) => e.id == project.categoryId)"
            :project="project"
            :isFeatured="true"
            :preloadedImg="projectImageCache[project.id]"
            @goToProject="goToProject"
          />
          <home-project-card v-for="project in nonFeaturedProjects" :key="project.id" 
            :category="categories.find((e) => e.id == project.categoryId)"
            :project="project"
            :isFeatured="false"
            :preloadedImg="projectImageCache[project.id]"
            @goToProject="goToProject"
          />
        </div>
      </div>
      <div v-else>
        <div v-if="loadingProjects" class="mt-5">
          <b-spinner></b-spinner>
        </div>
        <div v-else class="mt-3">
          <home-project-card v-for="(project, i) in selectedLoadedProjects" :key="project.id" :category="selectedCategory" :project="project" :isFeatured="i==0" @goToProject="goToProject"/>
        </div>
      </div>
      
    <!-- </b-container> -->
    <div class="bottom-margin-box"></div>
    </div>
    <BottomNavBar :currentIndex="0" @updateIndex="updateIndex"/>
  </div>
</template>

<script>
// import Home from './Home.vue';
import BottomNavBar from '../components/general/BottomNavBar.vue';
// import { Slider, SliderItem } from 'vue-easy-slider'
import { mapState } from "vuex";
import HomeCategoryCard from '../components/home/HomeCategoryCard.vue';
import { db, } from '../db';
import HomeProjectCard from '../components/home/HomeProjectCard.vue';

export default {
  name: 'CallToAction',
  // props: {
  //   resetToCall: {type: Boolean, default: false},
  // },
  components: {
    // Home,
    // Slider, 
    // SliderItem,
    BottomNavBar,
    HomeCategoryCard,
    HomeProjectCard,
  },
  data() {
    return {
      list: [
        { width: '100%', },
        { width: '100%', },
        { width: '100%',},
      ],
      showCategories: false,
      selectedCategory: {id: "all", "title": "All"},
      loadedProjects: [],
      loadingProjects: false,
    }
  },
  methods: {
    // assignBoosts() {
    //   db.collection("projects").get()
    //   .then((querysnap) => {
    //     let batch = db.batch();
    //     for (let i=0; i < querysnap.docs.length; i++) {
    //         const docRef = db.collection("projects").doc(querysnap.docs[i].id);
    //         batch.update(docRef, {
    //           boosts: fieldValue.increment(0)
    //         });
    //     }
    //     batch.commit()
    //     .then(() => {
    //       alert("done");
    //     })
    //   })
    // },
    
    categoryTitleFromProject(project) {
      // console.log(this.categories.map((e) => e.id));
      // console.log(project.categoryId);
      const category = this.categories.find((e) => e.id == project.categoryId);
      return category.title;
    },
    createNewProject() {
      this.$router.push("/create/project");
      this.moveUp();
    },
    goToCategory(category) {
      this.moveUp();
      this.selectCategory(category);
    },
    goToProject(project) {
        this.$router.push("/project/" + project.id);
    },
    moveUp() {
        window.scrollTo(0,0);
    },
    updateIndex(index) {
      if (index == 0) {
        this.showCategories = false;
      }
      else this.$emit("updateIndex", index);
    },
    selectCategory(category) {
      this.selectedCategory = category;
      console.log(category.title);
      console.log(this.selectedCategory.color);
      if (category.id != 'all') {
        this.$nextTick(() => {
          this.loadCategoryItems();
        })
      }
    },
    async loadCategoryItems() {
      this.loadingProjects = true;
      this.loadedProjects = [];

      try {
        // const queryRef = db.collection("projects").where("categoryId", "==", this.selectedCategory.id).orderBy("boosts", "desc");

        const queryRef = db.collection("projects").orderBy("boosts", "desc");

        const querysnap = await queryRef.get();

        if (querysnap.empty) {
          // no projects in this category at all
          console.log(querysnap);
          alert("This category is empty.");
          this.loadingProjects = false;
          this.selectedCategory = {id: 'all', title: "All"};
          return;
        }

        for (var i=0; i < querysnap.docs.length; i++) {
            const docSnap = querysnap.docs[i];

            let data = docSnap.data();
            data.id = docSnap.id;
            
            this.loadedProjects.push(data);
        }

        this.loadingProjects = false;
      }
      catch (err) {
        console.log(err);
        alert("Something went wrong loading the projects");
        this.loadingProjects = false;
      }
    }
  },
  computed: {
    ...mapState({
      parsillUser: (state) => state.parsillUser,
      categories: (state) => state.categories,
      projectImageCache: (state) => state.projectImageCache,
    }),
    categoryNames: function() {
      if (this.categories == null) return [];
      return this.categories.map((e) => e.title);
    },
    selectedLoadedProjects() {
      return this.loadedProjects.filter((e) => e.categoryId == this.selectedCategory.id);
    },
    featuredProjects() {
      if (this.loadedProjects == null) return [];
      
      let featured = [];
      // let categoriesSeen = [];

      for (let i=0; i < this.categories.length; i++) {
        const category = this.categories[i];
        for (let i=0; i < this.loadedProjects.length; i++) {
          if (this.loadedProjects[i].categoryId == category.id) {
            featured.push(this.loadedProjects[i]);
            break;
          }
        }
      }

      // for (let i=0; i < this.loadedProjects.length; i++) {
      //   if (categoriesSeen.length == this.categories.length) break;
      //   const project = this.loadedProjects[i];

      //   if (!categoriesSeen.includes(project.categoryId)) {
      //     categoriesSeen.push(project.categoryId);
      //     featured.push(project);
      //   }
      // }

      // alert(featured.length);

      return featured;
    },
    nonFeaturedProjects() {
      if (this.loadedProjects == null) return [];

      return this.loadedProjects.filter((e) => !this.featuredProjects.map((f) => f.id).includes(e.id));
    },
    categoryDropdownOptions() {
      let options = [
        {title: "All", id: "all",},
      ];

      if (this.categories == null) return [];

      this.categories.forEach((e) => {
        options.push({
          title: e.title,
          id: e.id,
          // featuredId: e.featuredId,
          color: e.color
        });
      });

      return options;
    },
    // orderedProjects() {
    //   const projectsNotFeatured = this.loadedProjects.filter((e) => e.id != this.selectedCategory?.featuredId);
    //   console.log(this.selectedCategory.featuredId);
    //   const featuredProject = this.loadedProjects.find((e) => e.id == this.selectedCategory.featuredId);


    //   let loadedProjects = [];

    //   if (featuredProject) {
    //     loadedProjects.push(featuredProject);
    //   }
      
    //   loadedProjects = [...loadedProjects, ...projectsNotFeatured];

    //   return loadedProjects;
    // }
  },
  created() {
    this.moveUp();
  },
  mounted() {
    this.loadCategoryItems();
    if (this.$route.params.category) {
      this.selectedCategory = this.categories.find((e) => e.id == this.$route.params.category);
    }
  }
  
}
</script>

<style scoped>
  .dropdown-container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    padding-left: 10px;
  }
  .action-button {
      width: 100vw;
      height: 45vw;
      background-color: blue;
  }

  .action-link {
    color: #09256e;
  }

  .center-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .bold {
        font-weight: bold;
        font-size: 3em;
    }


    .sizedbox-action {
      height: 20px;
    }

    .border-blue {
      border-color: #09256e !important;
    }

    #category-dropdown {
      width: 40vw;
    }
</style>
