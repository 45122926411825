<template>
    <div class="expanded-viewport">
        <b-container class="mt-4">
            <b-form class="mt-1" @submit.prevent="onSearch">
                <b-form-group id="search-input">
                    <b-form-input
                        id="input-1"
                        v-model="form.query"
                        type="search"
                        placeholder="Search"
                        size="lg"
                    >
                    </b-form-input>
                </b-form-group>
            </b-form>
            <div class="row ml-1 my-2 mx-2 label-search">
                Search for:
            </div>
            <div class="row">
                <div class="col">
                    <button v-if="searchType=='people'" type="button" class="btn btn-primary btn-lg py-1">People</button>
                    <button @click="updateSearchType('people')" v-else type="button" class="btn btn-light btn-lg py-1">People</button>
                </div>
                <div class="col">
                    <button v-if="searchType=='projects'" type="button" class="btn btn-primary btn-lg py-1">Projects</button>
                    <button @click="updateSearchType('projects')" v-else type="button" class="btn btn-light btn-lg py-1">Projects</button>
                </div>
            </div>
            <hr>
            <div v-if="searching==false">
                <b-list-group v-if="searchResults.length > 0">
                    <b-list-group-item v-for="item in searchResults" v-bind:key="item.id" class="d-flex align-items-center justify-content-between my-1 shadow-sm border">
                        <!-- <b-avatar class="mr-3"></b-avatar> -->
                        <span @click="viewItem(item)" v-if="searchType=='people'" class="mr-auto font-medium"><h4>{{item.username}}</h4></span>
                        <span @click="viewItem(item)" v-else class="mr-auto font-medium">{{item.title}}</span>
                        <div v-if="searchType=='people'">
                            <h5 v-if="following(item)"><b-badge class="parsillTertiaryBg">Following</b-badge></h5>
                            <!-- <h5 v-else><b-badge class="parsillBlueBg">Follow</b-badge></h5> -->
                            <button @click="followUser(item)" v-else type="button" class="btn parsillBlueBg white font-bold">Follow</button>
                        </div>
                    </b-list-group-item>
                </b-list-group>
                <h5 v-else class="mt-5">No Results Found</h5>
            </div>
            <div v-else>
                <b-spinner></b-spinner>
            </div>
        </b-container>
    </div>
</template>

<script>
import { db, fieldPath } from '../db';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            form: {
                query: null,
            },
            searchResults: [

            ],
            options: [
                { text: 'People', value: 'people' },
                { text: 'Projects', value: 'projects' },
                
            ],
            searching: false,
            followingUser: false,
        }
    },
    computed: {
        searchword: function() {
            return `Search ${this.searchType}`;
        },
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            searchType: (state) => state.searchType,
        }),
    },
    methods: {
        viewItem(item) {
            if (this.followingUser == true) return;
            if (this.searchType == "people") {
                this.$router.push("/viewprofile/" + item.id);
            } else {
                this.$router.push("/project/" + item.id);
            }
        },
        following(user) {
            var following = this.parsillUser.following ?? [];
            if (following.includes(user.id)) {
                return true;
            } else {
                return false;
            }
        },
        followUser(user) {
            this.followingUser = true;
            let personalRef = db.collection("users").doc(this.parsillUser.id);
            let userRef = db.collection("users").doc(user.id);

            var personalFollowing = this.parsillUser.following ?? [];
            personalFollowing.push(user.id);

            var userFollowers = user.followers ?? [];
            userFollowers.push(this.parsillUser.id);

            userRef
            .update({
                followers: userFollowers,
            })
            .then(() => {
                personalRef.update({
                    following: personalFollowing,
                })
                .then(() => {
                    this.getConnections();
                })
                .catch(err => {
                    console.log(`Error updating (my own) personalFollowing: ${err}`);
                });
            })
            .catch(err => {
                console.log(`Error updating (other) userFollowers: ${err}`);
            });

            this.followingUser = false;
        },
        onSearch() {
            var query = this.form.query.toLowerCase().trim();

            if (query == null || query == "") return;

            this.searching = true;

            if (this.searchType == "people") {
                db.collection("users")
                .where("username", "!=", null)
                .get()
                .then((querysnap) => {
                    this.searchResults = [];

                    for (var i=0; i < querysnap.docs.length; i++) {
                        let doc = querysnap.docs[i];
                        var userResult = doc.data();
                        if (userResult.username != null) {
                            if (userResult.username.toLowerCase().includes(query) || userResult.email.toLowerCase().includes(query)) {
                                userResult.id = doc.id;
                                if (userResult.id != this.parsillUser.id) {
                                    this.searchResults.push(userResult);
                                }
                            }
                        }
                        
                    }

                    this.searchResults.sort((a, b) => {
                        var nameA = a.username.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.username.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });

                    this.searching = false;
                })
                .catch(err => {
                    alert(`Error fetching search results. Please try again: ${err}`);
                    this.searching = false;
                });
            } else {
                db.collection("projects")
                .get()
                .then((querysnap) => {
                    this.searchResults = [];
                    
                    for (var i=0; i < querysnap.docs.length; i++) {
                        let doc = querysnap.docs[i];
                        
                        var projectResult = doc.data();
                        if (projectResult.title != null) {
                            if (projectResult.title.toLowerCase().includes(query)) {
                                projectResult.id = doc.id;
                                this.searchResults.push(projectResult);
                            }
                        }
                        
                    }

                    this.searchResults.sort((a, b) => {
                        var nameA = a.title.toUpperCase(); // ignore upper and lowercase
                        var nameB = b.title.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        // names must be equal
                        return 0;
                    });

                    this.searching = false;
                })
                .catch(err => {
                    alert(`Error fetching search results. Please try again: ${err}`);
                    this.searching = false;
                });
            }
        },
        updateSearchType(value) {
            this.searchResults = [];
            this.form.query = null;
            this.$store.commit("updateSearchType", value);
        },
        getConnections() {
            var allConnections = [];
            var idsToRunDupes = this.parsillUser.following + this.parsillUser.followers;

            var idsToRun = [...new Set(idsToRunDupes)] ?? [];
            
            db.collection("users").where(fieldPath.documentId(), "in", idsToRun)
            .get()
            .then((querysnap) => {
                let docs = querysnap.docs;
                for (var i=0; i < docs.length; i++) {
                    var connection = docs[i].data();
                    connection.id = docs[i].id;
                    allConnections.push(connection);
                }
            })
            .catch(err => {
                alert("Error reloading connections.");
                console.log(err);
            })
        }

    },
}
</script>

<style scoped>
    .blueFont {
        color: blue;
    }
    
    .greyFont {
        color: gray;
    }

    .font-bold {
        font-weight: bold;
    }

    .font-bold:visited {
        color: white;
    }

    .font-bold:active {
        color: white;
    }

    .center-horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .label-search {
        font-weight: bold;
        font-size: larger;
    }

    .label-type {
        font-weight: 500;
        font-size: large;
    }

    .font-med {
        font-size: medium;
        font-weight: bold;
    }
</style>