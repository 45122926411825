<template>
    <b-container>
        <b-form class="mt-5" @submit.prevent="onSubmit" v-if="show">
            <b-form-group id="email-input" class="py-1">
                <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    placeholder="Email"
                    size="lg"
                    :state="validateState('email')"
                    aria-describedby="input-1-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback
                class="validator-text pl-2"
                id="input-1-live-feedback"
                >Please enter a valid email</b-form-invalid-feedback>
            </b-form-group>
            <b-form-group id="password-input" class="py-1">
                <b-form-input
                    id="input-2"
                    v-model="form.password"
                    type="password"
                    placeholder="Password"
                    size="lg"
                    :state="validateState('password')"
                    aria-describedby="input-2-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback
                class="validator-text pl-2"
                id="input-2-live-feedback"
                >Please enter a valid password</b-form-invalid-feedback>
            </b-form-group>
            <b-button class="mt-5 mb-3" type="submit" variant="primary" size="lg">Log In</b-button>
        </b-form>
        <div>
            <button type="button" class="btn btn-link mt-3 mb-5" @click="forgotPassword">Forgot password</button>
            <div></div>
            <button type="button" class="btn btn-link mt-1 mb-5" @click="goToSignup">No account? Sign up here</button>
        </div>
    </b-container>
</template>

<script>
    import { validationMixin } from "vuelidate";
    import { required, minLength, email } from "vuelidate/lib/validators";
    import { auth } from "../../db";

    export default {
        mixins: [validationMixin],
        data() {
            return {
                form: {
                    email: null,
                    password: null,
                    confirmPassword: null,
                },
                show: true
            }
        },
        validations: {
            form: {
                email: {
                    required,
                    email,
                },
                password: {
                    required,
                    minLength: minLength(6),
                },
            }
        },
        methods: {
            goToSignup() {
                this.$emit("goToSignup");
            },
            forgotPassword() {
                this.$emit("goToForgotPassword");
            },
            validateState(name) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            onSubmit() {
                this.$v.form.$touch();
                if (this.$v.form.$anyError) {
                    return;
                }

                auth.signInWithEmailAndPassword(this.form.email, this.form.password)
                .then((userCredential) => {
                    console.log("signed in with uid: ", userCredential.user.uid);
                })
                .catch((error) => {
                    console.log(error);
                    alert("Error logging in: " + error.message);
                });

            },
        }
    }
</script>

<style>
    .btn-primary {
        background-color: #09256e !important;
    }
    .validator-text {
        font-size: 1rem;
        text-align: left;
    }
</style>