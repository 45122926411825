<template>
    <div class="mt-3">
        <div v-if="parsillUser.profileComplete == true" class="w-100">
            <h1 class="title py-2">{{parsillUser.username}}</h1>
            <div class="profile-row">
                <div>
                    <h4 class="px-4">Posts</h4>
                    <h3>
                       <span class="my-1 w-50">{{projectCount}}</span>
                    </h3>
                </div>
                <img :src="imgUrl" class="rounded-circle profile-dim" alt="Profile Picture" width="25%">
                <div>
                    <h4 class="px-4">Saves</h4>
                    <h3>
                        <span class="my-1 w-50">{{saveCount}}</span>
                    </h3>
                </div>
            </div>
            <p class="semibold font-large px-2 center-align mt-2">{{parsillUser.bio}}</p>
            <button v-if="isPublic != true" class="parsillBlueBg py-2 px-3 white rounded" @click="completeProfile">Edit Profile</button>
            <div v-else>
                <button @click="unfollow" v-if="following" class="parsillBlueBg py-2 px-3 white rounded">Unfollow</button>
                <button @click="followUser" v-else class="parsillBlueBg py-2 px-3 white rounded">Follow User</button>
            </div>
        </div>
        <div v-else>
            <button v-if="isPublic != true" type="button" class="btn btn-danger m-2 py-2 px-3" @click="completeProfile">Complete Profile</button>
        </div>
        <hr class="mx-2">
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { storage, auth, db } from '../../db';

export default {
    props: {
        parsillUser: null,
        projectCount: {type: Number, default: 0},
        saveCount: {type: Number, default: 0},
        isPublic: {type: Boolean, default: false},
        imgUrl: {type: String, default: "#"},
    },
    methods: {
        completeProfile() {
            this.$router.push("/profile/complete");
        },
        unfollow() {
            var shouldUnfollow = confirm("Unfollow user " + this.parsillUser.username + "?");
            if (!shouldUnfollow) return;

            var otherFollowers = this.parsillUser.followers ?? [];
            var yourFollowing = this.currentUser.following ?? [];

            // remove yourself from otherFollowers
            var index = otherFollowers.indexOf(this.currentUser.id);
            if (index > -1) otherFollowers.splice(index, 1);

            index = yourFollowing.indexOf(this.parsillUser.id);
            if (index > -1) yourFollowing.splice(index, 1);

            db.collection("users").doc(this.currentUser.id).update({
                following: yourFollowing,
            }).then(() => {
                    db.collection("users").doc(this.parsillUser.id).update({
                    followers: otherFollowers,
                }).catch(err => {
                    alert("Error updating follower status");
                    console.log(err);
                });
            }).catch(err => {
                alert("Error updating follower status");
                console.log(err);
            });
        },
        followUser() {

            let user = this.parsillUser;
            
            let personalRef = db.collection("users").doc(this.currentUser.id);
            let userRef = db.collection("users").doc(user.id);

            var personalFollowing = this.currentUser.following ?? [];
            personalFollowing.push(user.id);

            var userFollowers = user.followers ?? [];
            userFollowers.push(this.currentUser.id);

            userRef
            .update({
                followers: userFollowers,
            })
            .then(() => {
                personalRef.update({
                    following: personalFollowing,
                })
                .then(() => {
                    this.getConnections();
                })
                .catch(err => {
                    console.log(`Error updating (my own) personalFollowing: ${err}`);
                });
            })
            .catch(err => {
                console.log(`Error updating (other) userFollowers: ${err}`);
            });

            this.followingUser = false;
        },
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.parsillUser,
        }),
        following: function() {
            if (this.currentUser == null) return false;
            var flw = this.currentUser.following;
            if (flw == null) flw = [];
            return (flw ?? []).includes(this.parsillUser.id);
        }
    },
    mounted() {
        if (this.imgUrl == "#" || this.imgUrl == null) {
            console.log("null imgurl in profile header");
            const ref = storage.ref().child("users/").child(this.parsillUser.id).child("/profile");

            ref.getDownloadURL()
            .then((url) => {
                this.imgUrl = url;
                if (auth.currentUser.uid == this.parsillUser.id) {
                    this.$store.commit("updateProfilePicture", url);
                }
            })
            .catch((err) => {
                console.log(`Error getting profile image: ${err}`);
            });
        }
    }
   
}
</script>

<style scoped>
    .semibold {
      font-weight: 500;
    }

    .font-large {
        font-size: 1.2em;
    }

    .profile-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .profile-dim {
        width: 25%;
        aspect-ratio: 1;
        object-fit: cover;
    }

    .trim {
        max-height: 25%;
        overflow: hidden;
    }

    /* .center-align {
        text-align: center;
    } */

    .tier3 {
        color: darkred;
    }

    .tier4 {
        color: goldenrod;
    }

    .title {
        font-weight: bold;

    }
</style>