<template>
    <div>
        <b-col>
            <img class="mb-3 mt-2 center-horizontal logo" src="@/assets/logo.jpg" alt="Parsill"/>
            <div class="mx-3">
                <h2>Parsill is a place to store creative projects and gain inspiration from others.</h2>
            </div>
        </b-col>
        <SignupForm @goToLogin="goToLogin"/>
    </div>
</template>

<script>
import { } from '../db';

import SignupForm from '../components/signup/SignupForm.vue';
export default {
    
    components: {
        SignupForm
    },
    methods: {
        goToLogin() {
            this.$emit("goToLogin");
        },
    }
}
</script>

<style scoped>
    .logo {
        width: 70vw;
    }
</style>