<template>
    <div class="expanded-width parsillBlueBg">
        <b-navbar class="parsillBlueBg" fixed="top">
            <div class="w-100">
                <div class="d-none d-sm-block computer-warning p-3">Parsill is formatted for mobile. For best experience, open this link on your phone.</div>
                <div class="row justify-content-between">
                    <div class="col ml-4">
                        <h3 v-if="hasBackButton==true"><b-icon icon="arrow-left-square-fill" class="white mt-3" @click="navPop"></b-icon></h3>
                        <h3 v-else-if="hasLogout==true"><b-icon icon="box-arrow-left" class="white mt-3" @click="logout"></b-icon></h3>
                        <h3 v-else><b-icon icon="arrow-left-square-fill" class="mt-3" style="visibility:hidden"></b-icon></h3>
                    </div>
                    <div class="col" @click="logoClick">
                         <img class="p-1 shadow-sm parsillBlueBg center-horizontal" src="../../assets/logo.jpg" alt="Parsill" height="60px"/>
                    </div>
                    <div class="col">
                        <div v-if="hasShare == true" class="share-row">
                            <div>
                                <h3>
                                    <b-icon v-if="actionBootstrapIcon" :icon="actionBootstrapIcon" class="white mt-3" @click="actionClick"></b-icon>
                                    <b-icon v-else icon="square" class="mt-3" style="visibility:hidden"></b-icon>
                                </h3>
                            </div>
                            <div>
                                <h3>
                                    <b-icon icon="share-fill" class="white mt-3" @click="shareProject"></b-icon>
                                </h3>
                            </div>
                            
                        </div>
                        <h3 v-else>
                            <b-icon v-if="actionBootstrapIcon" :icon="actionBootstrapIcon" class="white mt-3" @click="actionClick"></b-icon>
                            <b-icon v-else icon="square" class="mt-3" style="visibility:hidden"></b-icon>
                        </h3>
                        
                    </div>
                </div>
                
            </div>
        </b-navbar>
        <div class="safearea"></div>
        <div class="d-none d-sm-block safearea"></div>
    </div>
</template>

<script>
    export default {
        props: {
            hasBackButton: {type: Boolean, default: false},
            title: {type: String},
            actionBootstrapIcon: {type: String},
            hasLogout: {type: Boolean, default: false},
            hasShare: {type: Boolean, default: false},
        },
        methods: {
            navPop() {
                this.$router.go(-1);
            },
            actionClick() {
                this.$emit("actionClick");
            },
            shareProject() {
                this.$emit("shareProject");
            },
            logout() {
                this.$emit("logout");
            },
            logoClick() {
                this.$emit("logoClick");
                this.$router.push({name: "Home", params: {index: 0}});
            }
        },
    }
</script>

<style scoped>

    .appbar-layout {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .appbar-item {
        display: table-cell;
        text-align: center;
    }

    .safearea {
        height: 65px;
    }

    .computer-warning {
        font-weight: bold;
        background-color: yellow;
        color: black;
        font-size: 1.2em;
    }

    .share-row {
        flex-direction: row;
        display: flex;
        justify-content: space-around;
    }

</style>